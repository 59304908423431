import {
  Button,
  Paper,
  TextField,
  Typography,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { encode, decode } from "base64-arraybuffer";
import React from "react";
const enc = new TextEncoder();
const dec = new TextDecoder();

const Asymmetric = () => {
  // const [key, setKey] = React.useState<CryptoKeyPair>();
  const [publicKey, setPublicKey] = React.useState<CryptoKey>();
  const [privateKey, setPrivateKey] = React.useState<CryptoKey>();
  const [publicKeyText, setPublicKeyText] = React.useState("");
  const [privateKeyText, setPrivateKeyText] = React.useState("");
  const [error, setError] = React.useState(false);
  const [encryptedText, setEncryptedText] = React.useState("");
  const [decryptedText, setDecryptedText] = React.useState("");

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (privateKeyText) {
      let publicKey: JsonWebKey;
      let privateKey: JsonWebKey;
      try {
        publicKey = JSON.parse(publicKeyText);
        privateKey = JSON.parse(privateKeyText);
      } catch (e) {
        setError(true);
        return false;
      }
      window.crypto.subtle
        .importKey(
          "jwk",
          publicKey,
          {
            name: "RSA-OAEP",
            hash: "SHA-512",
          },
          true,
          ["encrypt"]
        )
        .catch(() => setError(true))
        .then((publicKey) => {
          if (publicKey) {
            window.crypto.subtle
              .importKey(
                "jwk",
                privateKey,
                {
                  name: "RSA-OAEP",
                  hash: "SHA-512",
                },
                true,
                ["decrypt"]
              )
              .catch(() => setError(true))
              .then((privateKey) => {
                if (privateKey) {
                  setPrivateKey(privateKey);
                  setPublicKey(publicKey);
                }
              });
          }
        });
    } else {
      let publicKey: JsonWebKey;
      try {
        publicKey = JSON.parse(publicKeyText);
      } catch (e) {
        setError(true);
        return false;
      }
      window.crypto.subtle
        .importKey(
          "jwk",
          publicKey,
          {
            name: "RSA-OAEP",
            hash: "SHA-512",
          },
          true,
          ["encrypt"]
        )
        .catch(() => setError(true))
        .then((publicKey) => {
          if (publicKey) setPublicKey(publicKey);
        });
    }
  }

  return (
    <Paper className="paper">
      <Snackbar
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert onClose={() => setError(false)} severity="error">
          Key Importing Failed, Please Verify It is The Correct Format
        </Alert>
      </Snackbar>
      {window.crypto.subtle ? (
        <div>
          <form onSubmit={onSubmit}>
            <TextField
              label="Private Key"
              value={privateKeyText}
              onChange={(e) => setPrivateKeyText(e.target.value)}
            />
            <TextField
              style={{ marginTop: 10 }}
              required
              label="Public Key"
              value={publicKeyText}
              onChange={(e) => setPublicKeyText(e.target.value)}
            />
            <Button style={{ marginTop: 10, float: "right" }} type="submit">
              Import
            </Button>
          </form>
          <Button
            style={{ marginTop: 10 }}
            onClick={() => {
              window.crypto.subtle
                .generateKey(
                  {
                    name: "RSA-OAEP",
                    modulusLength: 4096,
                    publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
                    hash: "SHA-512",
                  },
                  true,
                  ["encrypt", "decrypt"]
                )
                .then((e) => {
                  setPrivateKey(e.privateKey);
                  setPublicKey(e.publicKey);
                  window.crypto.subtle
                    .exportKey("jwk", e.privateKey)
                    .then((e) => setPrivateKeyText(JSON.stringify(e)));
                  window.crypto.subtle
                    .exportKey("jwk", e.publicKey)
                    .then((e) => setPublicKeyText(JSON.stringify(e)));
                });
            }}
          >
            Generate Key
          </Button>
          <form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              if (publicKey) {
                window.crypto.subtle
                  .encrypt(
                    {
                      name: "RSA-OAEP",
                    },
                    publicKey,
                    enc.encode(decryptedText)
                  )
                  .then((e) => setEncryptedText(encode(e)));
              }
            }}
          >
            <TextField
              required
              label="Decrypted Text"
              style={{ marginTop: 10 }}
              value={decryptedText}
              onChange={(e) => setDecryptedText(e.target.value)}
            />
            <Button
              type="submit"
              style={{ float: "right", marginTop: 10 }}
              disabled={publicKey === undefined}
            >
              Encrypt
            </Button>
          </form>
          <form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              if (privateKey) {
                window.crypto.subtle
                  .decrypt(
                    {
                      name: "RSA-OAEP",
                    },
                    privateKey,
                    decode(encryptedText)
                  )
                  .then((e) => setDecryptedText(dec.decode(e)));
              }
            }}
          >
            <TextField
              required
              label="Encrypted Text"
              style={{ marginTop: 10 }}
              value={encryptedText}
              onChange={(e) => setEncryptedText(e.target.value)}
            />
            <Button
              style={{ float: "right", marginTop: 10 }}
              disabled={privateKey === undefined}
              type="submit"
            >
              Decrypt
            </Button>
          </form>
          <div style={{ marginBottom: 50 }} />
        </div>
      ) : (
        <Typography>SubtleCrypto Not Avalible On This Browser</Typography>
      )}
    </Paper>
  );
};

export default Asymmetric;
