import {
  Button,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { encode, decode } from "base64-arraybuffer";
import React from "react";
const enc = new TextEncoder();
const dec = new TextDecoder();

const Symmetric = () => {
  const [key, setKey] = React.useState<CryptoKey>();
  const [keyText, setKeyText] = React.useState("");
  const [error, setError] = React.useState(false);
  const [encryptedText, setEncryptedText] = React.useState("");
  const [decryptedText, setDecryptedText] = React.useState("");

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    let JSONKey: JsonWebKey;
    try {
      JSONKey = JSON.parse(keyText);
    } catch (e) {
      setError(true);
      return false;
    }
    window.crypto.subtle
      .importKey(
        "jwk",
        JSONKey,
        {
          name: "AES-CTR",
        },
        true,
        ["encrypt", "decrypt"]
      )
      .catch(() => setError(true))
      .then((e) => {
        if (e) setKey(e);
      });
  }

  return (
    <Paper className="paper">
      <Snackbar
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert onClose={() => setError(false)} severity="error">
          Key Importing Failed, Please Verify It is The Correct Format
        </Alert>
      </Snackbar>
      {window.crypto.subtle ? (
        <div>
          <form onSubmit={onSubmit}>
            <TextField
              label="Import Key"
              value={keyText}
              onChange={(e) => setKeyText(e.target.value)}
            />
            <Button style={{ marginTop: 10, float: "right" }} type="submit">
              Import
            </Button>
          </form>
          <Button
            style={{ marginTop: 10 }}
            onClick={() => {
              window.crypto.subtle
                .generateKey({ name: "AES-CTR", length: 256 }, true, [
                  "encrypt",
                  "decrypt",
                ])
                .then((e) => {
                  setKey(e);
                  window.crypto.subtle
                    .exportKey("jwk", e)
                    .then((e) => setKeyText(JSON.stringify(e)));
                });
            }}
          >
            Generate Key
          </Button>
          <form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              if (key) {
                const counter = window.crypto.getRandomValues(
                  new Uint8Array(16)
                );
                window.crypto.subtle
                  .encrypt(
                    {
                      name: "AES-CTR",
                      counter,
                      length: 64,
                    },
                    key,
                    enc.encode(decryptedText)
                  )
                  .then((e) =>
                    setEncryptedText(`${encode(e)};${encode(counter)}`)
                  );
              }
            }}
          >
            <TextField
              required
              label="Decrypted Text"
              style={{ marginTop: 10 }}
              value={decryptedText}
              onChange={(e) => setDecryptedText(e.target.value)}
            />
            <Button
              disabled={key === undefined}
              style={{ float: "right", marginTop: 10 }}
              type="submit"
            >
              Encrypt
            </Button>
          </form>
          <form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              if (key) {
                window.crypto.subtle
                  .decrypt(
                    {
                      name: "AES-CTR",
                      counter: decode(encryptedText.split(";")[1]),
                      length: 64,
                    },
                    key,
                    decode(encryptedText.split(";")[0])
                  )
                  .then((e) => setDecryptedText(dec.decode(e)));
              }
            }}
          >
            <TextField
              required
              label="Encrypted Text"
              style={{ marginTop: 10 }}
              value={encryptedText}
              onChange={(e) => setEncryptedText(e.target.value)}
            />
            <Button
              disabled={key === undefined}
              style={{ float: "right", marginTop: 10 }}
              type="submit"
            >
              Decrypt
            </Button>
          </form>
          <div style={{ marginBottom: 50 }} />
        </div>
      ) : (
        <Typography>SubtleCrypto Not Avalible On This Browser</Typography>
      )}
    </Paper>
  );
};

export default Symmetric;
