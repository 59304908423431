import * as React from "react";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import useDarkMode from "use-dark-mode";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Index from "./pages/Index";
import Header from "./components/Header";
import Symmetric from "./pages/Symmetric";
import Asymmetric from "./pages/Asymmetric";

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
  props: {
    MuiButton: { variant: "outlined" },
    MuiTextField: { variant: "outlined", fullWidth: true },
  },
});
const lightTheme = createMuiTheme({
  palette: {
    type: "light",
  },
  props: {
    MuiButton: { variant: "outlined" },
    MuiTextField: { variant: "outlined", fullWidth: true },
  },
});

function App() {
  const darkMode = useDarkMode();
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <Index />
          </Route>
          <Route exact path="/symmetric">
            <Symmetric />
          </Route>
          <Route exact path="/asymmetric">
            <Asymmetric />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
