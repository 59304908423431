import { Button, Paper, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <Paper className="paper">
      <Typography variant="h4">Key Type:</Typography>
      <Link to="/symmetric" style={{ textDecoration: "none" }}>
        <Button style={{ marginTop: 10 }}>Symmetric</Button>
      </Link>
      <br />
      <Link to="/asymmetric" style={{ textDecoration: "none" }}>
        <Button style={{ marginTop: 10 }}>Asymmetric</Button>
      </Link>
    </Paper>
  );
};

export default Index;
